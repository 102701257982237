.bg-grayscale {
    background-color: rgb(161, 160, 160);
    border-right: 2px solid gray;
  }
  
  .bg-flightGray {
    background-color: #ecf0f5;
  }
  
  .flyingFrom {
    background: url("../../assests/img/TravelShop/plane-light.png") no-repeat;
    background-position: 95% 50%;
    background-size: auto;
  }
  
  .searchInputField {
    width: 85%;
  }
  
  .flyingTo {
    background: url("../../assests/img/TravelShop/location.png") no-repeat;
    background-position: 95% 50%;
    background-size: auto;
  }
  
  .flightSection2 {
    display: grid;
    grid-template-columns: 20% 20% 26% 26%;
    column-gap: 20px;
  }
  
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-range.react-datepicker__day--keyboard-selected {
    background-color: rgb(197, 195, 195) !important;
    /* border-radius: 0px !important;
      margin-left:0px !important ;
      margin-right: 0px !important; */
    color: white !important;
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-selecting-range.react-datepicker__day--keyboard-selected {
    background-color: rgb(197, 195, 195) !important;
    color: white !important;
  }
  
  .react-datepicker__day--selected.react-datepicker__day--in-range,
  .react-datepicker__day--selected {
    background-color: #00c2ab !important;
    border-radius: 50% !important;
  }
  
  .react-datepicker__day--range-end,
  .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range {
    background-color: #00c2ab !important;
    border-radius: 50% !important;
  }
  
  .react-datepicker__day--keyboard-selected {
    background-color: white !important;
    color: black !important;
  }
  
  .departureOptions:hover {
    background-color: rgb(202, 202, 202);
  }
  
  .departureOptions:focus {
    background-color: rgb(202, 202, 202);
    outline: none;
  }
  
  .travelArrows {
    background: url("../../assests/img/TravelShop/dropdownArrow.png") no-repeat;
    background-position: 99% 50%;
    background-size: 1.5em 1.5em !important;
    cursor: pointer !important;
  }
  .travelArrows-arabic{
    background: url('../../assests/img/TravelShop/dropdownArrow.png') no-repeat;
    background-position: 2% 50%;
    background-size:  1.5em 1.5em !important;
    cursor: pointer !important; 
  }
  
  .travelBoxShadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .travelShopPassenger input[type="radio"] {
    transform: scale(1.5);
  }
  
  .travelSection2 {
    display: grid;
    grid-template-columns: repeat(4, minmax(260px, 1fr));
    column-gap: 20px;
  }
  
  .travelPlaceHolder {
    font-size: small;
    font-weight: 500;
  }
  .react-datepicker__header {
    border: none !important;
    background-color: transparent !important;
  }
  .custom-radio input {
    display: none;
  }
  .custom-radio label {
    margin-bottom: 0;
    padding-left: 30px;
    position: relative;
  }
  .custom-radio label span:before {
    content: "";
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    top: 2px;
    border: 1px solid #696969;
    border-radius: 100%;
  }
  .custom-radio input:checked + label span:before {
    border-color: #00af9a;
  }
  .custom-radio input:checked + label span:after {
    content: "";
    position: absolute;
    left: 4px;
    width: 12px;
    height: 12px;
    top: 6px;
    background-color: #00af9a;
    border-radius: 100%;
  }
  .sidebarinput input {
    margin-right: 10px !important;
  }
  @media (max-width: 1368px) {
    .travelSection2.twoWaytravelSection2 {
      grid-template-columns: repeat(2, minmax(260px, 1fr));
      grid-template-rows: 70px 60px;
    }
  }
  
  @media (max-width: 1094px) {
    .travelSection2.OneWaytravelSection2 {
      grid-template-columns: repeat(2, minmax(260px, 1fr));
      grid-template-rows: 70px 60px;
    }
    .searchSuggestionDiv {
      top: 168px !important;
    }
  }
  
  @media (min-width: 767px) and (max-width: 979px) {
    .travelShopPassenger input::placeholder {
      font-size: calc(3px + 1vw);
      font-weight: 400;
    }
  }
  .hrLine {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: #ffff00;
    height: 1px;
  }
  
  @media (max-width: 766px) {
    .footerMargin {
      margin-bottom: 7rem !important;
    }
  }
  .modal-dialog {
    margin: 0 !important;
  }
  .modal-content {
    border-radius: 0 !important;
    border: none !important;
  }
  
  /* Modals popups css */
  
  @media (max-width: 768px) {
    .policymodal,
    .filterModal {
      z-index: 5000 !important;
    }
    .policymodal .modal-dialog {
      bottom: 0% !important;
      width: 100% !important;
      position: fixed !important;
      max-height: calc(100vh - 40%) !important;
    }
    .stops-info li {
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
      color: #747c91;
    }
    .stops-info li:last-child {
      border: 0;
    }
  }
  .color-red {
    color: red;
  }
  .travelShopRadio {
    accent-color: #4681C2 ;
    width: 1.2rem;
    height: 1.2rem;
  }
  /* /breadcrumb */
  .check, .check-arabic{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    background-color: #ecf0f5;
    padding: 0 1rem;
  }
  
  .check1, .check1-arabic{
    display: flex;
    align-items: center;
    z-index: 3;
    background-color: #ecf0f5;
    padding: 0 1rem;
  }
  
  .check::after {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    background: rgba(29, 20, 20, 0.1);
    width: 175%;
    left: 110%;
    top: 55%;
  }
  
  .check-arabic::after {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    background: rgba(29, 20, 20, 0.1);
    width: 250%;
    top: 50%;
    right: 90%;
  }
  
  .checkwidth::after{
    width: 20vw;
  }
  
  .maincheck {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .checkname {
    margin-left: 10px;
  }
  .maininput {
    box-sizing: border-box;
    appearance: none;
    background: white;
    outline: 2px solid #00c2ab;
    width: 10px;
    height: 10px;
  }
  .bigcheck {
    width: 19px;
    height: 19px;
    background-color: #00c2ab;
  }
  .normalcheck {
    width: 10px;
    height: 10px;
    background-color: #00c2ab;
  }
  @media (max-width: 468px) {
  
    .maincheck {
      padding: 10px 10px 0 10px;
      gap: 3rem;
    }
    .check-arabic{
      display: flex;
      align-items:center;
      position: relative;
    }
    .check1{
      display: flex;
      align-items:center;
    }
    .bigcheck {
      width: 15px;
      height: 15px;
    }
    .check-arabic::after{
      content: " ";
      display: block;
      position: absolute;
      height: 1px;
      background: rgba(29, 20, 20, 0.1);
      width: 175%;
      right: 110%;
      top: 55%;
    }
    .checkwidth::after{
      width: 400%;
    }
    .maincheck{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .checkname{
      margin-left: 10px;
    }
    .maininput{
      box-sizing: border-box;
      appearance: none;
      background: white;
      outline: 2px solid #00C2AB;
      width: 10px;
      height: 10px;
    }
    .bigcheck{
      width: 19px;
      height: 19px;
      background-color: #00C2AB;
    }
    .normalcheck{
      width: 10px;
      height: 10px;
      background-color: #00C2AB;
    }
    .maincheck{
      padding: 10px 10px 0 10px;
    }
    .checkname{
      font-size: 0.8rem !important;
    }
    .bigcheck{
      width:15px;
      height: 15px;
    }
    .check::after{
      width:0%;
    }
    .check-arabic::after{
      width:0%;
    }
    .checkwidth::after{
      width:0%;
    }
  }
    @media (max-width:768px){
      .checkname{
        font-size: 0.8rem !important;
      }
      .bigcheck{
        width:15px;
        height: 15px;
      }
      .check::after{
        width:10% !important;
      }
      .checkwidth::after{
        width:0%;
      }
      .p-details-button{
        min-width: 100% !important;
      }
      .custom-box{
        padding: 3px !important;
      }
      .clear-input-ar-div{
        position: absolute;
        top: 39%;
        left: 25px;
      }
      .clear-input-div{
        position: absolute;
        top: 35%;
        right: 25px;
      }
      .clear-button-div{
        position: absolute;
        top: 53%;
        right: 25px;
      }
  
      .clear-button-ar-div{
        position: absolute;
        top: 53%;
        left: 25px;
      }
    }
  
    .flightCheckbox:checked[type=checkbox]::after{
    
      color:white ;
      content: '✓';
      background-color: #00c2ab;
      width: 1.1rem !important;
      height: 1.1rem !important;
      font-size: inherit !important;
    }
  
  .react-datepicker__month-container:nth-child(3) div:nth-child(1).flexibleDay{
      opacity: 0;
  }
  
  .backpop .modal-dialog {
    max-width: 100% !important;
  }
  .backbtn {
    color: white;
    border: 0;
  }
  .backpoptit {
    font-weight: 600;
  }
  .backpop .modal-content {
    width: 550px !important;
    border-radius: 10px !important;
  }
  /* passengermodal radio */
  .passengeModalRadio {
    accent-color: #4681C2 !important ;
    width: 2rem;
    height: 2rem;
    border: none !important;
  }
  
  .mywalletTravelImages {
    background-color: #00c2ab;
    cursor: pointer;
  }
  
  .mywalletTravelImages img {
    width: 25%;
  }
  
  .travelWalletCard {
    border: 2px solid rgb(141, 140, 140) !important;
  }
  
  /* Filter CSS */
  .card-header {
    /* background-color: transparent !important; */
    cursor: pointer !important;
  }
  .form-check-input {
    accent-color: #4681C2 !important;
  }
  .form-check-input::after {
    content: none !important;
  }
  .sidebarmain .form-check-label {
    color: #4681C2;
    font-weight: 500;
  }
  .sideop {
    opacity: 0.8;
  }
  .input-range__slider {
    background: white !important;
    border: 1px solid #4681C2 !important;
  }
  .input-range__track--active {
    background: #4681C2 !important;
  }
  .input-range__track {
    height: 0.2rem !important;
  }
  .toggle .btn.focus,
  .btn:focus {
    box-shadow: none !important;
  }
  .toggleup {
    transform: rotate(0deg);
    transition: 0.2s linear;
  }
  .toggledown {
    transform: rotate(180deg);
    transition: 0.2s linear;
  }
  .scroll {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .form-range {
    accent-color: #4681C2 !important;
    height: 0.2rem !important;
  }
  
  .flexibleOption label {
    color: black !important;
  }
  .mywalletTravelImages-gray {
    background-color: rgb(175, 171, 171);
    cursor: pointer;
  }
  .mywalletTravelImages-gray img {
    width: 25%;
  }
  /* Accordion  */
  .bg-color-none {
    background-color: transparent !important;
    border: none !important;
  }
  /* desktop Terms Modal  */
  @media (min-width: 768px) {
    .modal-dialog {
      margin-left: auto !important;
      margin-right: auto !important;
      border-radius: 10% !important;
    }
    .modal-content {
      border-radius: 0.3rem !important;
    }
    .clear-input-div {
      position: absolute;
      top: 50px;
      right: 25px;
    }
  
    .clear-input-ar-div {
      position: absolute;
      top: 35%;
      left: 25px;
    }
    .clear-button-div {
      position: absolute;
      top: 50px;
      right: 25px;
    }
  
    .clear-button-ar-div {
      position: absolute;
      top: 50px;
      left: 25px;
    }
  }
  
  .animate-bottom > .modal-dialog {
    animation: animateTop 0.4s !important;
  }
  .modal-backdrop.show {
    animation: fadeIn 0.5s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    40% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  @keyframes animateTop {
    from {
      bottom: -300px;
    }
  
    to {
      bottom: 0;
    }
  }
  
  .mobileCitySearch > .modal-dialog > .modal-content {
    height: 100vh !important;
  }
  /* UI filter Mobile  */
  .filterButton {
    background-color: none !important;
    border: 1px solid #cbcbcb !important;
    border-radius: 20px !important;
    display: flex !important;
    align-items: center;
    color: #888888 !important;
  }
  .filterButton-clicked {
    background-color: #4681C2 !important;
    border: 1px solid gray !important;
    border-radius: 20px !important;
    color: white !important;
  }
  .filterButton2 {
    background-color: none !important;
    border: 1px solid gray !important;
    /* border-radius: 20px !important; */
  }
  /* calender */
  .react-google-flight-datepicker .date-picker {
    position: relative;
    border-radius: 4px;
    background: white;
    padding: 0 !important;
  }
  .react-google-flight-datepicker .dialog-footer .submit-button {
    background: #00c2ab !important;
  }
  .react-google-flight-datepicker .day.selected::after {
    background-color: #00c2ab !important;
    border-radius: 100%;
  }
  @media (min-width:768px) {
  .react-google-flight-datepicker .dialog-date-picker {
    top: -121px !important;
    right: -50% !important;
    left: -100% !important;
  }
  .react-google-flight-datepicker .icon-calendar {
    width: 38px !important;
    height: 45px !important;
    fill: #00c2ab !important;
    margin-right: 12px !important;
  }
  .react-google-flight-datepicker .date:focus::after, .react-google-flight-datepicker .date.is-focus::after {
    /* border: 2px solid #00c2ab !important; */
    border: none !important;
   
  } 
  .react-google-flight-datepicker .date {
   
    padding: 0 0 !important;
  }
  .react-google-flight-datepicker .icon-arrow {
   display: none !important;
  }
  .react-google-flight-datepicker .date-picker-input {
    width: 150% !important;
  }
  .react-google-flight-datepicker .selected-date {
    justify-content: center !important;
  }
  }
  @media (max-width:768px) {
    .react-google-flight-datepicker .icon-calendar {
      width: 38px !important;
      height: 45px !important;
      fill: #00c2ab !important;
      margin-right: 12px !important;
    }
    .react-google-flight-datepicker .date:focus::after, .react-google-flight-datepicker .date.is-focus::after {
      /* border: 2px solid #00c2ab !important; */
      border: none !important;
     
    } 
  }
  .filterModal .modal-dialog {
    bottom: 0% !important;
    width: 100% !important;
    position: fixed !important;
    max-height: calc(100vh - 30%) !important;
  }
  
  .clear-button::before {
    content: "\2716";
  }
  .loader-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  .loader-wrap img::selection {
    background: transparent;
  }
  .loader-wrap img {
    pointer-events: none;
    animation: 1.5s linear infinite spinner-border;
  }
  .flights-tab-item {
    width: 33.33%;
    padding: 20px 25px;
    color: #797979;
    position: relative;
    cursor: pointer;
    text-align: center;
  }
  .flights-tab-item ::selection {
    background: transparent;
  }
  .flights-tab-item:last-child::before {
    content: none;
  }
  .flights-tab-item:before {
    content: "";
    position: absolute;
    right: 0px;
    top: 20px;
    bottom: 20px;
    border-right: 1px solid #b4b4b4;
  }
  .flights-tab-item.active {
    color: #212a30;
  }
  .flights-tab-item.active:after {
    content: "";
    position: absolute;
    right: 30px;
    left: 30px;
    bottom: 0px;
    height: 5px;
    background-color: #4681C2;
  }
  .flights-tab-item h5 span {
    vertical-align: middle;
  }
  .flights-tab-item h5 img {
    margin-left: 5px;
  }
  .flights-tab-item h6 {
    color: #212a30;
  }
  .flights-tab-item > span {
    color: #7d8890;
  }
  .flights-info {
    width: 75%;
    padding: 30px;
  }
  .flights-box.popular {
    border-color: #4681C2 !important;
  }
   .flights-option .btn:hover {
    border-color: #4681C2 !important;
    color: #4681C2 !important;
    background-color: transparent !important;
  }
  .flights,
  .flight-row {
    display: flex;
  }
  .flight-row {
    margin-left: 15px;
    flex: 1 1;
    font-size: 20px;
    color: #393939;
  }
  .flights + .flights {
    margin-top: 30px;
  }
  .airline-routes-info span {
    cursor: pointer;
    padding: 5px 12px;
    border-radius: 4px;
    color: #3c3c3c;
    display: inline-block;
    font-size: 18px;
    margin-right: 12px;
  }
  .best {
    background-color: #d9dff5;
  }
  .fees {
    background-color: #f0f0f0;
  }
  .stops {
    background-color: #f5e4f4;
  }
  .airline-time p {
    margin: 0;
  }
  .timeline {
    position: relative;
    width: 25%;
    text-align: center;
    padding: 0 25px;
    color: #7d8890;
  }
  .timeline p {
    line-height: normal;
    margin-bottom: 8px;
  }
  .c-timeline {
    height: 3px;
    background-color: #4681C2;
    width: 100%;
    margin-bottom: 5px;
  }
  .flight-name img {
    max-height: 35px;
    margin-bottom: 5px;
  }
  .flight-name h5 {
    margin-bottom: 0;
    font-size: 18px;
    color: #7d8890;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .flight-name {
    width: 22%;
  }
  .airline-time {
    width: 19%;
    padding: 0 15px;
  }
  .flights-option {
    width: 25%;
    border-left: 1px solid #d1d1d1;
    padding: 20px;
    font-size: 16px;
  }
  .flights-option p {
    margin-bottom: 0;
  }
  .points p {
    font-size: 14px;
  }
  .pagination li a {
    background-color: transparent;
    border: 0;
    font-size: 18px;
    color: #acacac;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    padding: 0;
    cursor: pointer;
    border-radius: 3px;
    margin: 0;
  }
  .pagination li.page-item.disabled a {
    background-color: #e3e3e3;
  }
  .pagination li a.next,
  .pagination li.page-item a.next:hover,
  .pagination li a.prev,
  .pagination li.page-item a.prev:hover {
    background-color: #4681C2;
    color: #fff;
  }
  .pagination li:not(.disabled) a.next svg path,
  .pagination li:not(.disabled) a.prev svg path {
    stroke: #fff;
  }
  .pagination li.disabled a.next svg path,
  .pagination li.disabled a.prev svg path {
    stroke: #707070;
  }
  .pagination li.page-item.active a,
  .pagination li.page-item a:hover {
    background-color: transparent;
    color: #4681C2 !important;
  }
  .search-travel-info {
    background-color: #4681C2;
    padding: 12px 20px;
    color: #fff;
    display: flex;
    align-items: center;
  }
  .search-travel-detail {
    margin-left: 20px;
  }
  .search-travel-detail p {
    margin-bottom: 0;
  }
  .search-travel-detail svg {
    margin: 0 10px;
  }
  .search-travel-info svg path {
    stroke: #fff;
  }
  .flights-result-data {
    font-size: 12px;
  }
  .flights-result-data span {
    color: #505050;
  }
  .choose-flight-info li {
    list-style: none;
    color: #969696;
    padding: 0 10px;
  }
  .choose-flight-info li span {
    vertical-align: middle;
  }
  .choose-flight-info li svg {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
  .choose-flight-info li svg path {
    color: #00af9a;
  }
  .flight-collapse {
    display: flex;
    align-items: center;
  }
  .flight-collapse-header {
    margin-left: 20px;
  }
  .flight-collapse svg path {
    fill: #000000;
    opacity: 0.6;
  }
  .flight-collapse-arrow {
    width: 40px;
    height: 40px;
    background-color: #f0f0f0;
    text-align: center;
    border-radius: 4px;
    line-height: 38px;
    margin-left: 10px;
  }
  .bounding-icon svg {
    width: 34px;
    height: 34px;
  }
  .bounding h5 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .bounding {
    padding-left: 30px;
  }
  .bounding span {
    color: #a0a0a0;
  }
  .airline-name,
  .airline-time,
  .airline-direct,
  .more-info,
  .airline-points {
    width: 25%;
    padding: 0 15px;
  }
  .airline-name h5 {
    font-size: 18px;
    margin: 10px 0 0;
  }
  .flight-routes-info {
    padding: 15px;
    display: flex;
    align-items: center;
  }
  .airline-time h6 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .airline-time h5 {
    margin-bottom: 5px;
  }
  .airline-time p {
    color: #242424;
    font-weight: 500;
  }
  .airline-direct-inner {
    max-width: 100px;
  }
  .airline-direct .direct {
    height: 2px;
    background-color: #4681C2;
    width: 100%;
    position: relative;
    margin: 10px 0;
  }
  .airline-direct .direct:before {
    content: "";
    position: absolute;
    right: 0px;
    width: 8px;
    height: 2px;
    background-color: #4681C2;
    top: -3px;
    transform: rotate(45deg);
  }
  .airline-direct .direct::after {
    content: "";
    position: absolute;
    right: 0px;
    width: 8px;
    height: 2px;
    background-color: #4681C2;
    top: 3px;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .more-info .card-header {
    background-color: #e8e8e8 !important;
    border-radius: 40px !important;
    padding: 10px 20px !important;
    text-align: center;
    display: inline-block;
  }
  .more-info .card-header img {
    margin-right: 10px;
  }
  .flight-info {
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .flight-timeline {
    position: relative;
    height: 80px;
    width: 2px;
    background-color: #4681C2;
    margin: 13px 3px;
  }
  .flight-timeline:before {
    content: "";
    position: absolute;
    left: -2px;
    top: -12px;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: #4681C2;
  }
  .flight-timeline:after {
    content: "";
    position: absolute;
    left: -2px;
    bottom: -12px;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: #4681C2;
  }
  .bonding-time {
    display: flex;
    align-items: center;
  }
  .bonding-info {
    padding-left: 60px;
    display: flex;
  }
  .bonding-info svg path {
    fill: #b5b5b5;
  }
  .bonding-time h5 {
    max-width: 95px;
    width: 100%;
    font-size: 18px;
  }
  .flight-options {
    max-width: 300px;
    width: 100%;
  }
  .flight-options li {
    list-style: none;
    color: #888888;
    margin-bottom: 10px;
  }
  .flight-options li.disabled {
    opacity: 0.5;
  }
  .flight-options li svg,
  .flight-options li span {
    vertical-align: middle;
  }
  .flight-options li svg {
    margin-right: 10px;
  }
  .flight-title {
    position: relative;
  }
  
  .flight-title::after {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-color: #d9d9d9;
    bottom: -10px;
    right: 0;
    left: 0;
    margin: 0 auto;
    background: #fff;
  }
  
  .flight-title h6 {
    font-size: 18px;
    color: #1f1f1f;
  }
  .flight-person-count {
    font-size: 18px;
    color: #585858;
  }
  .flight-person-count b {
    color: #1f1f1f;
  }
  .flight-icon svg {
    width: 20px;
  }
  .flight-icon svg path {
    fill: rgba(255,255,255,0.7);
    stroke-width: 0;
  }
  .flight-mobile-info {
    margin-bottom: 30px;
  }
  .flight-mobile-info p {
    margin-bottom: 0;
  }
  .airline-time-info {
    margin-top: 5px;
  }
  .airline-time-info svg {
    opacity: 0.8;
  }
  .airline-time-info span {
    font-size: 18px;
    margin-left: 5px;
    vertical-align: middle;
  }
  
  .flight-step {
    display: flex;
    padding: 40px;
    border: 1px solid #ddd;
  }
  .step-flight-inner {
    display: flex;
    align-items: center;
  }
  .layover-time{
    background-color: #f0f0f0;
    width: 100%;
    text-align: center;
    padding: 4px 10px;
    font-size: 14px;
    color: #212529;
    font-weight: 500;
  }
  .step-flight-wrap {
    text-align: center;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .step-flight span {
    font-size: 14px;
  }
  .step-flight-info {
    padding-left: 40px;
    flex: 1 1 auto;
  }
  .flights-up:before, .flights-down:before {
    content: "";
    position: absolute;
    left: -40px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #3C3C3C;
  }
  .step-flight-inner .flights-up:after,
  .step-flight-inner:last-child .flights-down:after {
    content: "";
    position: absolute;
    left: -49px;
    top: 4px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 100%;
    border: 2px solid #2E2E2E;
    box-shadow: 0px 0px 0px 6px #ffffff;
    z-index: 1;
  }
  .step-flight-inner:not(:first-child) .flights-up:after {
    top: -20px;
    width: 10px;
    border: 0;
    height: 10px;
    background-color: #00AF9A;
    left: -44px;
  }
  .step-flight-inner:last-child .flights-down:before {
    height: 20px;
  }
  .step-flights {
    flex: 1 1 auto;
    position: relative;
    max-width: 180px;
    width: 100%;
  }
  .step-flights p {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .step-flights span {
    color: #7C7C7C;
    font-size: 16px;
  }
  .flights-up, .flights-down {
    padding-bottom: 20px;
    position: relative;
  }
  .step-flight-inner:last-child .flights-down {
    padding-bottom: 0;
  }
  .time-range-slider {
    width: 100%;
    margin-top: 10px;
  }
  .time-range-slider .react_time_range__handle_wrapper {
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid #000;
    width: 16px;
    height: 16px;
  }
  .time-range-slider .react_time_range__handle_container, 
  .time-range-slider .react_time_range__tick_marker__large, 
  .time-range-slider .react_time_range__tick_label, 
  .time-range-slider .react_time_range__tick_marker {
    display: none;
  }
  .time-range-slider .react_time_range__rail__inner {
    height: 0px;
  }
  .time-range-slider .react_time_range__track, 
  .time-range-slider .react_time_range__track__disabled {
    height: 3px;
    background-color: #00008b !important;
  }
  .flight-time-tab {
    text-transform: capitalize;
    flex: 1;
    text-align: center;
    border-bottom: 2px solid #ddd;
    padding: 15px 10px;
    cursor: pointer;
  }
  .flight-time-tab:not(.active):hover {
    background-color: #eee;
  }
  .flight-time-tab h5 {
    margin-bottom: 0;
  }
  .flight-time input {
    margin-right: 10px;
  }
  
  .flight-time-tab.active {
    border-bottom-color: #4681C2;
  }
  html[dir="rtl"] .btn svg {
    transform: scale(-1);
  }
  html[dir="rtl"] .ml-2 {
    margin-left: 0!important;
    margin-right: .5rem!important;
  }
  html[dir="rtl"] .mr-2 {
    margin-left: 0.5rem!important;
    margin-right: 0rem!important;
  }
  html[dir="rtl"] .ml-3 {
    margin-left: 0rem!important;
    margin-right: 1rem!important;
  }
  html[dir="rtl"] .ml-auto {
    margin-right: auto!important;
    margin-left: 0 !important;
  }
  html[dir="rtl"] .pl-4 {
    padding-right: 1.5rem!important;
    padding-left: 0!important;
  }
  html[dir="rtl"] .mr-3 {
    margin-left: 1rem!important;
    margin-right: 0!important;
  }
  html[dir="rtl"] .text-right {
    text-align: left!important;
  }
  html[dir="rtl"] .border-right {
    border-left: 1px solid #dee2ed !important;
    border-right: 0 !important;
  }
  html[dir="rtl"] .flights-tab-item h5 img {
    margin-right: 5px;
    margin-left: 0;
  }
  html[dir="rtl"] .flights-tab-item:before {
    right: auto;
    left: 0;
  }
  html[dir="rtl"] .sidebarinput input {
    margin-right: 0px !important;
    margin-left: 10px;
  }
  html[dir="rtl"] .flight-time input,
  html[dir="rtl"] .choose-flight-info li svg,
  html[dir="rtl"] .more-info .card-header img {
    margin-right: 0px;
    margin-left: 10px;
  }
  html[dir="rtl"] .choose-flight-info li span {
    vertical-align: text-bottom;
  }
  html[dir="rtl"] .form-check-inline {
    margin-right: 0;
    margin-left: .75rem;
  }
  html[dir="rtl"] .flight-collapse-header {
    margin-right: 20px;
    margin-left: 0;
  }
  html[dir="rtl"] .bounding {
    padding-left: 0px;
    padding-right: 30px;
  }
  html[dir="rtl"] .flight-row {
    margin-right: 15px;
    margin-left: 0;
  }
  html[dir="rtl"] .flights .plane {
    margin-bottom: 20px !important;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  html[dir="rtl"] .step-flight-wrap {
    padding-left: 40px;
    padding-right: 0px;
  }
  html[dir="rtl"] .step-flight-info {
    padding-left: 0px;
    padding-right: 40px;
  }
  html[dir="rtl"] .flight-collapse svg,
  html[dir="rtl"] .bounding-icon svg {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  html[dir="rtl"] .pagination li a.next,
  html[dir="rtl"] .pagination li a.prev {
    line-height: 42px;
  }
  html[dir="rtl"] .pagination li a.next svg {
    -moz-transform: scale(-1);
    -webkit-transform: scale(-1);
    transform: scale(-1);
  }
  html[dir="rtl"] .pagination li a.prev svg,
  html[dir="rtl"] .search-travel-info > svg,
  html[dir="rtl"] .airline-direct .direct {
    -moz-transform: scale(-1);
    -webkit-transform: scale(-1);
    transform: scale(-1);
  }
  html[dir="rtl"] .flight-options li svg {
    margin-left: 10px;
    margin-right: 0;
  }
  html[dir="rtl"] .flights-up:before, html[dir="rtl"] .flights-down:before {
    left: auto;
    right: -40px;
  }
  html[dir="rtl"] .step-flight-inner .flights-up:after, html[dir="rtl"] .step-flight-inner:last-child .flights-down:after {
    left: auto;
    right: -49px;
  }
  html[dir="rtl"] .step-flight-inner:not(:first-child) .flights-up:after {
    left: 0;
    right: -44px;
  }
  html[dir="rtl"] .search-travel-detail {
    margin-right: 20px;
    margin-left: 0px;
  }
  @media (min-width: 768px) {
    html[dir="rtl"] .text-md-right {
        text-align: left!important;
    }
  }
  @media screen and (max-width: 1600px) {
    .flight-options {
      max-width: 250px;
    }
    .flight-info {
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 1400px) {
    .timeline {
      padding: 0 15px;
    }
    .airline-time {
      padding: 0 10px;
    }
    .flights-info {
      padding: 20px;
    }
    .bonding-time h5 {
      max-width: 65px;
    }
    .bonding-info {
      padding-left: 40px;
    }
    .flight-options {
      max-width: 230px;
    }
    .flight-info {
      padding: 0;
    }
    .airline-name, .airline-time, .airline-direct, .more-info, .airline-points {
      padding: 0 10px;
    }
    .more-info .card-header {
      padding: 10px 15px !important;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1280px) {
    .flight-row,
    .flight-name h5 {
      font-size: 16px;
    }
    .flights-info {
      width: 70%;
    }
    .flights-option {
      width: 30%;
    }
    .timeline {
      padding: 0 10px;
    }
    .airline-name, .airline-time, .airline-direct, .more-info, .airline-points {
      padding: 0 5px;
    }
    .flights-info {
      padding: 15px;
    }
    .bonding-info {
      padding-left: 30px;
    }
    .bonding-time h5 {
      max-width: 60px;
    }
    .flight-options li {
      font-size: 14px;
    }
    .flight-options {
      max-width: 200px;
    }
    .airline-name h5 {
      font-size: 15px;
    }
    .airline-points h4 {
      font-size: 22px;
    }
  }
  @media screen and (max-width: 1024px) {
    .flights-info,
    .flights-option {
      width: 100%;
    }
    .flights-option {
      border-left: 0;
      border-top: 1px solid #d1d1d1;
    }
  }
  @media screen and (max-width: 991px) {
    .loader-wrap img {
      max-width: 180px;
    }
    .flight-step {
      padding: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    .loader-wrap img {
      max-width: 120px;
    }
    .flights + .flights {
      margin-top: 15px;
    }
    .flight-row {
      margin-left: 0;
      align-items: flex-start;
    }
    .flights-info {
      padding: 10px;
    }
    .flight-name {
      display: flex;
      align-items: center;
      width: 24%;
    }
    .flight-name img {
      max-height: initial;
      margin-bottom: 0px;
      margin-right: 5px;
      max-width: 25px;
    }
    .flight-row,
    .flight-name h5 {
      font-size: 12px;
    }
    .flight-name h5 {
      margin-top: 2px;
    }
    .airline-time {
      font-size: 13px;
      display: flex;
      flex-wrap: wrap;
      width: 32%;
    }
    .airline-time p {
      margin: 0 3px;
    }
    .airline-time p:last-child {
      color: #797979;
    }
    .timeline {
      font-size: 9px;
      padding: 0 3px;
      width: 12%;
      margin-top: 8px;
      white-space: nowrap;
    }
    .timeline p {
      -moz-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      margin-bottom: 0;
    }
    .airline-time:nth-child(4) {
      flex-direction: row-reverse;
      padding-right: 20px;
    }
    .c-timeline {
      opacity: 0.3;
      height: 1px;
      margin-bottom: 10px;
    }
    .c-timeline:before {
      content: "";
      position: absolute;
      left: 2px;
      width: 8px;
      height: 1px;
      background-color: #4681C2;
      top: -3px;
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .c-timeline::after {
      content: "";
      position: absolute;
      left: 2px;
      width: 8px;
      height: 1px;
      background-color: #4681C2;
      top: 3px;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .flights:nth-child(2) .c-timeline:before,
    .flights:nth-child(2) .c-timeline:after {
      left: auto;
      right: 2px;
    }
    .flights:nth-child(2) .c-timeline:before {
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .flights:nth-child(2) .c-timeline:after {
      -moz-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .flights-option {
      position: relative;
      padding-right: 120px;
      padding-left: 10px;
    }
    .flights-option h4 {
      color: #4681C2;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .flights-option h4 span {
      color: #797979;
      margin-right: 5px;
      font-size: 14px;
    }
    .flights-option .btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 110px;
      bottom: 0;
      background-color: transparent !important;
      border: 0;
      color: #00c2ab !important;
      border-left: 1px solid #ebebeb !important;
      border-radius: 0 !important;
    }
    .points p,
    .points p span {
      font-size: 12px;
      color: #09008a !important;
    }
    .flight-options li {
      color: #313133;
    }
    .airline-name,.more-info {
      width: 50%;
      padding: 0 15px;
    }
    .airline-name h5 {
      margin: 5px 0 0;
      font-size: 12px;
    }
    .airline-points {
      width: 100%;  
    }
    .flight-routes-info {
      padding: 0;
    }
    .flight-routes-info .airline-time,
    .flight-routes-info .airline-direct {
      width: 33.33%;
    }
    .flight-routes-info .airline-time p {
      font-size: 14px;
    }
    .flight-routes-info .airline-time:last-child {
      display: flex;
      flex-direction: row-reverse;
    }
    .airline-points h4 {
      font-size: 16px;
      color: #797979;
    }
    .airline-points h4 span {
      font-size: 20px;
    }
    .airline-direct-inner p {
      font-size: 10px;
    }
    .airline-direct .direct {
      opacity: 0.3;
    }
    .flight-routes-info { 
      flex-wrap: wrap;
      padding: 5px 0 10px;
    }
    .airline-direct-inner {
      margin: 0 auto;
    }
    .step-flight h5 {
      font-size: 14px;
      max-width: 160px;
    }
    .step-flight-info .flight-options {
      margin: 0;
      position: relative;
      width: 100%;
      max-width: 100%;
    }
    .step-flight-info .flight-options:before {
      content: "";
      position: absolute;
      left: -40px;
      top: -10px;
      bottom: 0;
      width: 2px;
      background-color: #3C3C3C;
    }
    .step-flight-info .step-flight-inner:last-child .flight-options:before {
      content: normal;
    }
    html[dir="rtl"] .c-timeline {
      -moz-transform: scale(-1);
      -webkit-transform: scale(-1);
      transform: scale(-1);
    }
    html[dir="rtl"] .c-timeline:before,
    html[dir="rtl"] .c-timeline::after {
      left: -1px;
    }
    html[dir="rtl"] .flights:nth-child(2) .c-timeline:before,  html[dir="rtl"] .flights:nth-child(2) .c-timeline:after {
      left: auto;
      right: -1px;
    }
    html[dir="rtl"] .timeline p {
      -moz-transform: translateX(50%);
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
    }
    html[dir="rtl"] .flight-row {
      margin-right: 0px;
    }
    html[dir="rtl"] .flights-option {
      padding-right: 10px;
      padding-left: 120px;
    }
    html[dir="rtl"] .flights-option .btn {
      left: 0;
      right: auto;
      border-left: 0 !important;
      border-right: 1px solid #ebebeb !important;
    }
    html[dir="rtl"] .flights-option h4 span {
      margin-left: 5px;
      margin-right: 0px;
    }
    html[dir="rtl"] .step-flight-info .flight-options:before {
      right: -40px;
      left: auto;
    }
  }
  @media screen and (max-width: 640px) {
    .flight-options {
      max-width: 100%;
      margin-left: 85px;
      margin-top: 15px;
    }
    .flight-info {
      flex-wrap: wrap;
    }
    .airline-direct-inner span {
      white-space:nowrap;
    }
    
  }
  @media screen and (max-width: 575px) {
    .loader-wrap img {
      max-width: 80px;
    }
    .flight-collapse svg {
      max-width: 30px;
    }
    .bounding-icon svg {
      width: 28px;
      height: 28px;
    }
    .bounding {
      padding-left: 20px;
    }
    .step-flight-wrap {
      height: 60vh;
    }
    .flight-person-count {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 480px) {
    .bonding-info {
      padding-left: 0px;
    }
    .flight-info {
      position: relative;
      padding-top: 30px;
    }
    .flight-options {
      margin-left: 30px;
    }
    .bonding-info svg {
      position: absolute;
      left: -10px;
      top: 0;
    }
    .airline-name {
      padding-right: 5px;
    }
    .more-info {
      padding-left: 5px;
    }
    .more-info .card-header {
      padding: 10px 12px !important;
      font-size: 12px;
    }
    .flight-routes-info .airline-time p {
      font-size: 13px;
    }
    .airline-time h6 {
      font-size: 16px;
    }
    .flight-routes-info .airline-direct {
      padding: 0 20px;
    }
    .airline-time:nth-child(4) {
      padding-right: 10px;
    }
    .airline-time h5 {
      font-size: 16px;
    }
    .flight-name {
      width: 21%;
      flex-wrap: wrap;
    }
    .step-flight-info {
      padding-left: 25px;
    }
    .flights-up:before, .flights-down:before {
      left: -20px;
      width: 1px;
    }
    .step-flight-inner .flights-up:after, .step-flight-inner:last-child .flights-down:after {
      left: -25px;
      width: 12px;
      height: 12px;
      border-width: 1px;
    }
    .step-flight-info .flight-options:before {
      left: -20px;
      width: 1px;
    }
    .step-flight-inner:not(:first-child) .flights-up:after {
      left: -23px;
      height: 8px;
      width: 8px;
      top: -18px;
    }
    .step-flight h5 {
      font-size: 14px;
    }
    .step-flight img {
      max-width: 60px;
    }
    .flight-step {
      padding: 15px;
    }
    .step-flight-wrap {
      padding-right: 10px;
      max-width: 80px;
      width: 100%;
      height: 50vh;
    }
    .step-flight {
      line-height: normal;
    }
    .step-flight h5 {
      font-size: 10px;
    }
    .step-flight span {
      font-size: 10px;
    }
    .step-flight-inner:first-child .step-flights .flights-up {
      padding-top: 0 !important;
    }
    .flights-up h4,
    .flights-down h4 {
      font-size: 16px;
    }
    .flight-options li {
      font-size: 12px;
    }
    .layover-time {
      padding: 4px 7px;
      font-size: 13px;
    }
    .flights-up, .flights-down {
      padding-bottom: 10px;
      position: relative;
    }
    .step-flights p {
      margin-bottom: 10px;
      font-size: 12px;
    }
    .step-flights span {
      font-size: 12px;
    }
    html[dir="rtl"] .step-flight-wrap {
      padding-left: 10px;
    }
    html[dir="rtl"] .step-flight-info {
      padding-right: 25px;
    }
    html[dir="rtl"] .step-flight-inner .flights-up:after, html[dir="rtl"] .step-flight-inner:last-child .flights-down:after {
      right: -25px;
    }
    html[dir="rtl"] .flights-up:before, html[dir="rtl"] .flights-down:before,
    html[dir="rtl"] .step-flight-info .flight-options:before {
      right: -20px;
    }
    html[dir="rtl"] .step-flight-inner:not(:first-child) .flights-up:after {
      right: -23px;
    }
  }
  /* check */
  
  
  .mywalletTravelSection{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 20px;
  }
  
  /* @media screen and (max-width:1332px) {
    .mywalletTravelSection{
      grid-template-columns: repeat(2,1fr);
    }
  }
  @media screen and (max-width:990px) {
    .mywalletTravelSection{
     display: flex;
     gap: 2;
    }
  } */

.add-to-calendar-dropdown{
  display: flex;
  width: 100%;
}

.atc-item{
  width: 100%;
  color: #00c2ab !important;
  background-image: url('/src/assests/img/TravelShop/mywallet-calendar.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
  