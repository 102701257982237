
:root{
    --primaryColorTheme:#005E9A;
    --primaryColorTheme04:#005185;
    --secondaryColorTheme:#28A1D4;
    --primaryBtnTheme:#005E9A;
    --primaryTextTheme:#272629;
    --topHeader:#000000;
    --linkColor:#4d4f5c;
    --linkActive: #000000;
    --linkHover: #03a8b6;
    --primaryBtnBackgroundColor:#005E9A;
    --primaryBtnBorderColor:#00B2AE;
    --primaryBtnFontColor:#FFFFFF;
    --secondaryBtnBackgroundColor:#28A1D4;
    --secondaryBtnBorderColor:#28A1D4;
    --secondaryBtnFontColor:#FFFFFF;
    --headings:#000000;
    --sunHeadings:#000000;
    --productTitleColor:#000000;
    --productBrandColor:#4d4f5c;
    --productValueColor:#4d4f5c;
    --primaryColor:#136982;
    --secondaryColor:#03a8b6;
    --textcolor:#4d4f5c;
    --primaryBgColor:#136982;
    --secondaryBgcColor:#03a8b6;
    --headingWhite:#ffffff;
    --bgGrey:#E7E7E7;
    --lightGreyBackgroud:#F4F7FA;
}
/* new design for contact Us */
/* .main{
  position:relative;
  width:100%;
 height: auto;
  }
  .left-card{
  position:absolute;
  left:0px;
  width:30%;
  height:25vh;
  background-color:#005E9A;
  border-radius:4px;
  margin-left:20px;
  z-index:1;
  margin-top:20px;
  padding-left: 10px;
  padding-top: 10px;
  }
  .right-card{
  position:absolute;
  right:0px;
  width:70%;
  height:auto;
  border-radius:4px;
  }
  .form-container{
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  background-color:#F4F7FA;
  border-radius:4px;
  }

.left-square{
  background-color: var(--primaryBtnBackgroundColor);
  padding: 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
} */
/* accordin in faq */
.sidebar-nav-menu-item {
  display: block;
  margin-bottom: 8px;
}
.sidebar-nav-menu-item-body {
  /*This is equivalent to display: none*/
  background-color: #F4F7FA;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 400ms, opacity 400ms 0ms;
  padding-left: 0.938rem;
}
.sidebar-nav-menu-item.item-active .sidebar-nav-menu-item-body {
  /*This is equivalent to display: block*/
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}

.sidebar-nav-menu-item-head-help {
  /*To move button in header to right*/
  position: absolute;
  right: 35px;
  
}

.sidebar-nav-menu-item-head, .faq-sidebar-nav-menu-item-head {
  /*To style header*/
  background-color: #F4F7FA;
  color: #000;
  padding: 15px;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
}

.sidebar-nav-menu-item-head:after {
  /*To add caret down icon - by default*/
  content: '\f0d7'; /* fa-caret-down */
  font-family: 'FontAwesome';
  position: absolute;
  right: 12px;
}
.faq-sidebar-nav-menu-item-head:after {
  /*To add caret down icon - by default*/
  content: '\f0d7'; /* fa-caret-down */
  font-family: 'FontAwesome';
  position: absolute;
  right: 12px;
  top: 20%;
}
.sidebar-nav-menu-item.item-active .faq-sidebar-nav-menu-item-head:after, .sidebar-nav-menu-item.item-active .sidebar-nav-menu-item-head:after {
  /*This will add caret up when collapsiable is active*/
  content: '\f0d8'; /* fa-caret-up */
}
/* end here */
.typo-secondary-faq{
color: var(--secondaryColorTheme) !important;
}
.bg-light-grey{
  background-color: var(--lightGreyBackgroud,#F4F7FA);
}
.btn-deno {
    display: inline-block;
    font-weight: 400;
    color: var(--primaryTextTheme);
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: var(--lightGreyBackgroud) !important;
}
.faq-heading{
  font-weight: bolder;
  color: #E37521;
}
.info-text{
font-weight: 400;
font-size: 12px;
line-height: 16px;
}
.sqaure-contact{
background-color: var(--primaryColorTheme);
height:30vh;
line-height: 30px;
padding: 10px;
color: var(--headingWhite);
border-radius: 4px;
}
.heading-primary{
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color:var(--primaryColorTheme);
  }
.heading-dark{
font-weight: 600;
font-size: 32px;
line-height: 36px;
display: flex;
align-items: center;
color: var(--primaryTextTheme);
}
.heading-white-con{
font-weight: 700;
font-size: 36px;
line-height: 40px;
display: flex;
align-items: center;
color: #FFFFFF;
}
.body-typo{
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: var(--primaryTextTheme);
}
.body-typo1{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--primaryTextTheme);
  }
.model-text-dark{
font-weight: 700;
font-size: 24px;
line-height: 28px;
color: var(--primaryTextTheme);
}
.Order-summery-card{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--lightGreyBackgroud);
  background-clip: border-box;
  border: none !important;
  border-radius: 8px !important;
}
.order-summery-heading{
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--primaryColorTheme);
  flex: none;
  order: 0;
  flex-grow: 0;
}
.order-summery-body-text{
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #272629;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.ellipisis{
  display: -webkit-box;
  max-width: 400px;
  height: 50px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer-caption{
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.object-fit-contain{
  object-fit: contain;
}
@media screen and (max-width:750px){
  .heading-white-con{
    font-weight: 600;
    font-size: 30px !important;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    }
}
.txt-medium{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color:var(--headingWhite);
  flex: none;
  order: 0;
  flex-grow: 0;
}
.txt-small{
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: var(--headingWhite);
  flex: none;
  order: 0;
  flex-grow: 0;
}
.primary-btn-drc{
  background-color: var(--primaryBtnBackgroundColor,#005E9A);
  color: var(--primaryBtnFontColor,#FFFFFF);
}
.btn-logout-drc{
background: #FF6060;
color: var(--primaryBtnFontColor) !important;

}
.secondary-btn-drc{
  background-color: var(--secondaryBtnBackgroundColor,#28A1D4);
  color: var(--secondaryBtnFontColor,#FFFFFF);
  border: 1px solid #FFFFFF;
}
.search-input{
  width: 100%;
  border-radius: 4px !important;
  border: 1px solid var(--primaryColorTheme04,#005185)  !important;
  padding: 1% 6% !important;
  margin: 1% 1% !important;
  outline: 0;
  background-color: var(--primaryColorTheme04,#005185);
  color:var(--headingWhite,#FFFFFF);
}
.search-icon-container{
  position: relative;
}
 .search-icon{
  position: absolute;
  right:0.313rem;
  top:0.25rem;
}
.search-icon img{
  width: 20px;
  height: 20px;
}
.search-input::placeholder{
  color:var(--headingWhite,#FFFFFF);
}
#lang-select {
  background-color: var(--primaryColorTheme) !important;
  color: var(--headingWhite) !important;
  border: none !important;
  display: inline-block;
  font: inherit;
  line-height: none !important;
  padding: 0.5em 1em 0.5em 1em;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
#lang-select:active{
  border: none !important;
}
#lang-select:focus{
  outline: none !important;
}
#lang-select option{
  background-color: var(--headingWhite) !important;
  color: var(--linkActive) !important;
}
#user-select {
  background-color: var(--secondaryColorTheme,#28A1D4) !important;
  color: var(--headingWhite) !important;
  border: none !important;
  display: inline-block;
  font: inherit;
  line-height: none !important;
  padding: 0.5em 1em 0.5em 1em;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  outline: none !important;
}
#user-select:active{
  border: none !important;
}
#user-select:focus{
  outline: none !important;
}
#user-select option{
  background-color: var(--headingWhite) !important;
  color: var(--linkActive) !important;
}
.svg-color path{
  fill: var(--primaryColorTheme,#000000)!important;
}
.svg-color circle{
  fill: var(--primaryColorTheme,#000000)!important;
}
.heading-white{
  color: var(--headingWhite);
}
.header_footer{
  background-color: var(--primaryColorTheme04,#005185) !important;
}
.footer{
  background-color: var(--primaryColorTheme,#000000) !important;
  margin-top: auto;
}
.footer-drc-2{
  background-color: var(--primaryColorTheme04,#005185) !important;
}
.primary-search-btn{
background-color: var(--primaryBtnBackgroundColor) !important;
}
.category-link{
    color: var(--linkColor, #4d4f5c);
}
.category-link:hover{
    color: var(--linkActive, #6fac45) !important ;
}
.active-cat{
    color: var(--linkActive, #6fac45) !important ;
    /* border-color: #00c2ab !important; */
  }
  .active-link{
    color: var(--linkActive, #6fac45) !important ;
    /* border-color: #00c2ab !important; */
  }
  .btnTxt{
    color: #136982!important;
  }
  .btnTextDark{
    background-color: #063041 !important;
  }
  .btnTextBlack{
    background-color: var(--primaryBtnTheme,#000000) !important;

  }
  .color-green{
    color: #03a8b6 !important;
  }
  .color-skyblue{
    color: var(--primaryTextTheme,#000000 ) !important;
  }
  .headings{
      color: var(--primaryTextTheme,#000000 ) !important;
  }
  .sub-headings{
    color: var(--textcolor,#4d4f5c ) !important;
  }
  .product_title{
      color: var(--primaryTextTheme,#000000);
  }
  .product_brand{
      color: var(--productBrandColor,#4d4f5c);
  }
  .product_value{
      color: var(--productValueColor,#4d4f5c)
  }
  .primary-color{
    color: #136982!important;
  }
  .second-color{
    color: var(--secondaryColor , #6fac45) !important;
  }
  .selected-value{
    border: 2px solid var(--primaryBtnTheme,#000000) !important ;
  }
  .secondary-color-border{
    background-color: var(--primaryColorTheme,#005E9A) !important;
    color: var(--primaryBtnFontColor,#FFFFFF) !important;
    /* border: 2px solid var(--secondaryColorTheme,#000000) !important ; */
  }
  .primary-btn{
    color: #fff !important;
    background-color: var(--primaryBtnTheme, #000000) !important ;
  }
  .btn-outline-primary{
    border-color:  var(--primaryBtnTheme, #000000) !important;
  }
  .btn-outline-primary:hover{
    background-color: var(--primaryBtnTheme, #000000) !important ;
    color: #fff !important;
  }
  .btn-sky{
    color: #fff !important;
    background-color: #00B2AE !important;
  }
  .secondary-color{
    color: #00B2AE !important;
  }
  .muted-color{
    color: #484A56;
  }
  .bg-color{
    background-color: var(--primaryColor ,#136982) !important;
  }
  .bg-color-grey{
    background-color: var(--bgGrey ,#E7E7E7) !important;
  }
  .bg-color-black{
    background-color: var(--primaryBtnTheme ,#000000) !important;
  }
  .border-color{
    border: 2px solid #00B2AE !important;
  }
  .loginModal{
    text-decoration: underline;
    color: var(--linkActive , #6fac45) !important
}

.btn-secondary{
  color: var(--primaryBtnTheme,#000000) !important;
 border-color: 3px solid var(--primaryBtnTheme,#000000) !important;
 background-color: #fff !important;
}
.form-control:focus{
 border-color: var(--primaryColor,#136982 ) !important;
}

.height-25{
  height : 2.5rem
}
.font-small{
  font-size: 1rem;
}
/* error text */
.text-error {
  color: red;
  font-size: 14px;
  font-weight: 500;
}
.text-black{
  color: var(--sunHeadings) !important;
}

/* kantar buttons */
.btn-black-primary{
    color: #000000;
    font-size: 16px;
    border: 1px solid #000;
    background-color: #fff;
    padding: 0.5rem;
    width: 60%;
}
.btn-black-secondary {
  color: #fff;
  font-size: 16px;
  border: 1px solid #000;
  background-color: #000;
  padding: 1rem;
 width: fit-content;
}
.custom-border{
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.custom-border-left{
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.custom-border-top{
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
/* logo */
.app-logo{
  max-width: 10.625rem;
  height: 3.625rem;
}
.app-logo #custom-tenant-logo{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .heading-white{
    color: var(--headingWhite);
    font-size: 12px;
  }
}

.view-image{
  width: 16.4rem;
  height: 10rem;
  line-height: 10.2625rem;
  text-align: center;
  /* transition: 0.2s transform, box-shadow; */
  /* box-shadow: 0px 0px 5px 1px #00000010; */
  /* border-radius: 20px; */
}
.view-image img{
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  text-align: center;
}
@media screen and (max-width:900px){
  .view-image{
    width: 10.625rem;
    height: 6.6875rem;
    line-height: 6.6875rem;
    text-align: center;
    /* transition: 0.2s transform, box-shadow;
    box-shadow: 0px 0px 5px 1px #00000010; */
  }
  .view-image img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    text-align: center;
  }
}
.card-center{
  justify-items: center !important;
}
.order-border{
  border-top: 1px solid var(--primaryBtnTheme);
  width: 100px;
}

@media screen and (max-width: 750px) {
  .custom-margin-right{
    margin-right: 5% !important;
  }
  #ddl-quantity{
    padding: 0.5em 2.25em 0.5em 1em !important;

  }
}
.overlap-container{
  position: relative;
}
.overlap-card-left{
  position: absolute;
  width: 38%;
  left: 5rem;
  top: 30px;
}
