/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap'); */
/* @font-face {
  font-family: 'Noto-Regular';
  src: url('./assests/fonts/Noto_Sans/NotoSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
} */

body {
  font-family: 'Noto Sans';
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

a {
  color: gray !important;
}
a:hover {
  color: var(--linkHover, #03a8b6) !important ;
}

a.nohover:hover { color: white !important;  }

a.offerCardHover:hover{text-decoration: none;}

.marginBottm{
  margin-bottom: 100px !important;
}

.lanSpecific{
  direction: ltr !important;
  justify-content: flex-start !important;
}

.arrow-indicator {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC');
  background-position: calc(100% - .5rem), 100% 0 ;
  background-position-x:100%  ;
  background-position-y: 50%;
  background-size:  1.5em 1.5em !important;
  background-repeat: no-repeat !important;
  cursor: pointer !important;  
}