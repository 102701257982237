@import url(../../assests/css/Global.css);

.redemption-page {
    background-color: #EDEDE9;
    height: 100vh;
}

.redemption-card {
    margin: 10px;
    padding: 20px;
    margin-top: 5%;
    background-color: #fff;
    height: 500px;
    width: 425px;
}
.redemption-card2 {
    margin: 10px;
    padding: 20px;
    margin-top: 5%;
    background-color: #fff;
    height: 400px;
    width: 425px;
}
.kantar-img {
    max-width:  100%;
    height: 2.823rem;
}

.redeem-text {
    font-size: 18px;
    font-weight: bold;
    color: var(--primaryColorTheme);
}
.redeem-text2 {
    font-size: 14px;
    font-weight: bold;
    color: var(--primaryTextTheme);
}

.enter-code-text {
    font-size: 16px;
}

.redeem-code-input {
    padding: 10px;
    min-height: 50px;
    color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(0, 0, 0, 1);
}

.redeem-input-not-valid {
    padding: 10px;
    min-height: 50px;
    color: rgba(255, 0, 0, 0.5);
    border: 1px solid rgba(248, 0, 0, 1);
}
.redeem-input-not-valid:focus{
    outline: 1px solid rgba(248, 0, 0, 1);
}
.not-valid-msg{
    font-size: 16px;
    color: rgba(244, 6, 6, 1);
}
.redeem-code-input:focus {
    outline: none;
}

.redeem-btn {
    align-self: center;
    background-color: var(--primaryBtnTheme,#000000);
    border: 1px solid var(--primaryBtnTheme,#000000);
    min-height: 6vh;
    width: 75%;
    color: #fff;
}

.help-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
}

.promo-code {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
}

.proceed-no-btn {
    color: var(--primaryBtnTheme,#000000);
    font-size: 16px;
    border: 1px solid var(--primaryBtnTheme,#000000);
    background-color: #fff;
    height: 39px;
    margin-right: 20px;
    width: 40%;
}

.proceed-yes-btn {
    color: #fff;
    font-size: 16px;
    border: 1px solid var(--primaryBtnTheme,#000000);
    background-color: var(--primaryBtnTheme,#000000);
    height: 39px;
    margin-right: 20px;
    width: 40%;
}
