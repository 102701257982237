@import url(./Global.css);
@import url(./font.css);
frame {
  display: block;
}

a {
  cursor: pointer !important;
}
.App {
  width: 100vw;
  text-align: left;
}

.main-div {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
}
.scroll-top{
  position: relative;
  z-index: 9990;
  left: 90%;
}
.scroll-top img{
  width: 3rem;
  transform: rotate(270deg);
  position: fixed;
  top: 82%;
}

.thumbLogo {
  width: 220px;
  padding: 10px;
}

.form-control {
  /* font-size: 3rem;   */
  /* margin-left: 130px; width: 400px; height: 32px; */
}

.border-dashed {
  border-bottom: 2px dotted #cccccc;
}

.searchAlign {
  padding-left: 140px !important;
}

.searchInput {
  border-radius: 0% !important;
}

.searchTxt {
  font-size: 17px;
  color: white;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .searchBtn{
  background-color: #00c2ab !important;
  border-color: #00c2ab !important;
  border-radius: 0% !important;
  min-width: 80px;
  min-height: 50px;
} */
.filter-green {
  /* filter: invert(49%) sepia(96%) saturate(1180%) hue-rotate(134deg) brightness(96%) contrast(101%); */
  /* filter: invert(26%) sepia(94%) saturate(726%) hue-rotate(155deg) brightness(97%) contrast(85%) !important; */
  filter: invert(61%) sepia(41%) saturate(563%) hue-rotate(52deg)
    brightness(91%) contrast(90%);
}
.icon-white {
  filter: invert(1);
}
.z-index-top {
  z-index: 1000 !important;
}
.search-autocomplete-list {
  z-index: 1200 !important;
  top: 3.1rem;
}

/* Category Sec color filters */
.color-box {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 10%;
  margin-right: 1px;
}
.black {
  background-color: black;
}
.orange {
  background-color: orangered;
}
.blue {
  background-color: blue;
}
.brown {
  background-color: brown;
}
.countryDrop {
  padding: 30px 15px 10px 15px;
  margin-left: 85px;
  width: 192px;
}
.w-20 {
  width: 20% !important;
}
.w-19 {
  width: 20rem !important;
}
.w-80 {
  width: 80% !important;
}
.w-fit-content {
  width: fit-content !important;
}
/* .active-cat{
  color: #00c2ab !important;
  border-color: #00c2ab !important;
} */

/* .active-link{
  color: #00c2ab !important;
  border-color: #00c2ab !important;
} */
.cursor-pointer {
  cursor: pointer !important;
}
.outline-color-green,
.outline-color-green:focus {
  outline: #00c2ab 1px solid !important;
  -moz-outline-radius: 0px;
  z-index: 2;
}

.bg-color-blue {
  background-color: var(--primaryBgColor, #136982) !important;
  color: #eeeeee;
}
.bg-color-blue2{
  background-color: #4681C2;
  color: #fff;
}
.bg-color-skyblue {
  background-color: #ece3fc !important;
}
.bg-color-gray {
  background-color: #f3ede3;
}
hr {
  background-color: rgb(200, 200, 200) !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-end {
  text-align: right !important;
}
.btn-border {
  border: 1px #00c2ab solid !important;
  border-radius: 0px !important;
  color: #00c2ab !important;
  font-weight: bold !important;
}

.mw-10 {
  min-width: 10rem;
}
.font-size-sm {
  font-size: small !important;
}
.border-bottom-green {
  border: none !important;
  border-bottom: 2px solid #00c2ab !important;
  border-radius: 0px !important;
}
.border-color-green {
  border: 1px solid var(--primaryColor, #136982) !important ;
  border-radius: 0px !important;
}
/* .selected-value{
  border: 3px #00c2ab solid !important;
} */
.info {
  border: 3px #00c2ab solid !important;
  color: #00c2ab;
  padding: 0 0.56rem;
  margin-right: 0.5rem;
  line-height: 0px !important;
  border-radius: 50%;
}
.bg-color-green {
  background-color: var(--secondaryBgcColor, #6fac45) !important;
}
.border-radius-0 {
  border-radius: 0px !important;
}
.card-rate {
  border-color: #cccccc !important;
}
.card-rate:hover {
  color: #00c2ab !important;
  border-color: #00c2ab !important;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.card-hover{
  background-color: #fff !important;
  border-radius: 8px !important;
  border: 1px solid #E8EDF2;
  height: auto;
}
.card-link{
  text-decoration: none !important;

}
.card-hover:hover {
  transition: 0.3s box-shadow;
  /* transform: scale(1.02) !important; */
  box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.2) !important;;
}
.bg-color{
  background-color: #F4F7FA !important
}
.new-address-tab{
  background-color: #005E9A;
  border: 2px solid #005E9A;
  color: white;
  padding: 10px;
  width: 40%;
  height: 45px;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
}
.new-address-tab-2{
  background-color: #F4F7FA;
  border: 2px solid #E8EDF2;
  color: #000000;
  padding: 10px;
  height: 45px;
  width: 40%;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
}
.drc-title{
  color: #005E9A;
}
.add-scrollbar {
  /* height:150px; */
  height: 100%;
  overflow-y: scroll;
}
.text-underline{
  text-decoration: underline;
}
.redirect-links::after{
  content: "|";
  margin-left: 2rem;
}
.add-new-address-wrapper{
  background-color: #F4F7FA;
  height: 95%;
  width: 40%;
  border-radius: 8px;
}
.add-new-address-wrapper-mb{
  background-color: #F4F7FA;
  height: 95%;
  width: 90%;
  border-radius: 8px;
}
.add-address-inp{
  padding: 10px;
  border: 2px solid #E8EDF2;
  border-radius: 4px;
  height: 45px;
  /* width: 90%; */
}
.add-address-inp:focus{
  outline: none;
}
.delivery-order-summary{
  /* background-color: #F4F7FA; */
  margin-top: 100px;
  border-radius: 4px;
}
.btn-disable:disabled{
  opacity: 0.7;
}
.cart-counter{
  position: absolute;
  font-size: 0.6rem;
  top: -2px;
  left: 12px;
  color: #28A1D4;
  width: 1rem;
  height: 1rem;
 display: flex;
 align-items: center;
 justify-content: center;
  border-radius: 50%;
  background-color: white;
  border: 0.2em solid #28A1D4;
}
.cart-container{
  margin: 0 !important;
}
.shoping-details{
  max-width: 100% !important;
}
.cart-details h2{
  font-size: 2rem !important;
}
.cart-img img{
  max-width: 11rem !important;
}
.cart-img-mb img{
  max-width: 7rem !important;
}
.cart-img{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.card-width{
  width: 100% !important;
}
.empty-cart{
  top: 0 !important;
}
/* Cart */
.shoping-details{
  background: #F4F7FA;
  padding: 1rem;
  max-width: 95%;
}
.shoping-details-2{
  background: #F4F7FA;
}
.order-summary-card{
  margin-top: 25px;
  background-color: #F4F7FA !important;
  border: none !important;
  border-radius: 6px !important;
}
.custom-margin-right{
  margin-right: 13%;
}
.custom-border-rad{
  border-radius: 6px;
}
.my-acc-headings{
  background-color: #F4F7FA;
  padding: 0.75rem 1.25rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.my-wallet-card-bg{
  background: #F4F7FA;
}
.arrow-muted{
  opacity: 0.4;
}
.btn-accordion[aria-expanded="true"] .arrow-img {
  transform: rotate(90deg);
  transition: .3s ease-in-out;
}
.cursor-none{
  cursor: text !important;
}
.card-details-img{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
}
.cart-details{
  width: -webkit-fill-available;
  justify-content: space-between;
}

.cart-count::after{
  content: attr(count);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  position: absolute;
  top: 5%;
  right: 1.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mob-cart-count::after{
  content: attr(count);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  position: absolute;
  top: -3%;
  right: 31.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-container{
  margin: 0 3rem;
}

.card-width{
  width: 18rem;
}
.empty-cart{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 5rem;
}
.empty-cart button{
  width: fit-content;
}
.delivery-order-summary-btn{
  height: 45px;
  background-color: white;
  border: 2px solid #005E9A;
  color: #005E9A;
  border-radius: 4px;
  cursor: pointer;
}
.mr-10{
  margin-right: 10rem;
}
.new-address-btn{
  height: 45px;
  width: 45%;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #005E9A;
  cursor: pointer;
}
#new-addresses-btn{
  height: 45px;
  width: 20%;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #005E9A;
  cursor: pointer;
}
.new-address-btn-mb{
  height: 45px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #005E9A;
  cursor: pointer;
}

.new-address-btn-2{
  height: 45px;
  width: 40%;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  background-color: #E8EDF2;
  cursor: pointer;
}
.border-rad{
  background-color: #F4F7FA;
  border-radius: 6px;
}
.border-rad-top{
  background-color: #F4F7FA;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.border-rad-bottom{
  margin-top: 2px;
  background-color: #F4F7FA;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.buy-now-card{
  background-color: #F4F7FA;
  border-radius: 6px;
}
.buy-now-input{
  border: 2px solid #E8EDF2;
  border-radius: 4px ;
}
.buy-now-input:focus{
  outline: none;
}
.my-account-search{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  background-color: #F4F7FA;
  border: 2px solid #E8EDF2;
}
.my-account-search-mb{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #F4F7FA;
  border: 2px solid #E8EDF2;
}
.my-account-search-inp{
  width: 100%;
  background-color: #F4F7FA;
  border: none; 
}
.my-account-search-inp:focus{
  outline: none;
}
.my-account-item{
  border-radius: 8px;
  background-color: #F4F7FA;
  margin: 10px 0;
}
.my-account-brand{
  color: #28A1D4;
  font-weight: 400;
}
.my-acc-top{
  background-color: #F4F7FA;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.my-acc-bottom{
  margin-top: 2px;
  background-color: #F4F7FA;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.status-btn{
  height: 45px;
  width: 180px;
  background-color: #FFFFFF;
  color: #28A1D4;
  border-radius: 4px;
  padding: 7px !important;
  border: none;
}
.status-btn-mb{
  height: 40px;
  width: 120px;
  background-color: #FFFFFF;
  color: #28A1D4;
  border-radius: 4px;
  padding: 7px !important;
  border: none;
}
.user-details{
  background-color: #F4F7FA;
  border-radius: 8px;
}
.user-details-title{
  color: #005E9A;
}
.max-height-50 {
  max-height: 50vh !important;
  overflow-y: scroll !important;
}
.allCategories {
  margin: 30px;
  border: none;
  display: inline-block;
  width: 250px;
  color: darkblue;
  text-transform: uppercase;
  font-family: "Times New Roman";
}
.carousel-indicators {
  bottom: -60px !important;
  /* margin: auto 0 !important; */
}

.carousel-indicators li {
  background: url("carousel-indicator2.png") no-repeat center center !important;
  width: 18px !important;
}
.carousel-indicators li.active {
  background: url("carousel-indicator-active2.png") no-repeat center center !important;
}
.indicator-size li,
.indicator-size li.active {
  background-size: 10px 10px !important;
}
.indicator-size li {
  margin-left: 10px !important;
}
.carousel-item.active.slate {
  width: 20% !important;
  display: none !important;
}

.Doffers {
  display: inline-block;
  margin: 10px;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  width: 210px;
  height: 265px;
  padding: 20px;
}

.offers {
  width: 170px;
}
/* .carousel-indicators li.active.slate{
    padding-left: 150px !important;
  } */

.carousel-control-prev-icon {
  background-image: url("carouser-arrow-left.png") !important;
  width: 40px !important;
  height: 40px !important;
}
.carousel-control-next-icon {
  background-image: url("carouser-arrow-right.png") !important;
  width: 40px !important;
  height: 40px !important;
}

.btncategory {
  font-weight: 500 !important;
  font-size: 1rem !important;
}

/* .btnTxt{
  color: darkblue !important;
} */
.dropdown-toggle::after {
  justify-content: end !important;
}
.btnhighlighted {
  border: 2px solid #00c2ab !important;
}

.mw-11 {
  max-width: 11% !important;
}
.mw-17 {
  max-width: 17% !important;
}
.border-color-blue {
  border: 2px #4681C2 solid !important;
}
.color-blue {
  color: #4681C2;
}
/* .color-green{
  color: #00c2ab !important;
} */
.dropdown-menu-full {
  width: 300px !important;
}
.dropdown {
  width: 100% !important;
}

.lineThrough {
  text-decoration-line: line-through;
}
.hr-line {
  border-color: #e6e6e6 !important;
}
.bg-light-gray {
  background-color: #eeeeee !important;
}

.selectdiv:after {
  /* border: 3px solid #4d4f5c;
  content: "";
  font: normal normal normal 17px/1 FontAwesome;
  color: black;
  font-weight: bolder;
  font-size: 19px;
  right: -9%;
  top: 20px;
  border-width: 0 3px 3px 0px;
  height: 5px;
  padding: 5px;
  transform: rotate(44deg);
  position: absolute;
  pointer-events: none;
  cursor: pointer; */
}
.selectdiv:before {
  /* content: "";
  font: normal normal normal 17px/1 FontAwesome;
  color: black;
  right: 4%;
  top: 5px;
  border-left: 1px solid #4d4f5c;
  height: 51px;
  position: absolute;
  pointer-events: none;
  cursor: pointer; */
}
.selectdivar::after {
  right: 107%;
  top: 22px;
  height: 0.3vw;
  padding: 0.3vw;
}
.selectdivar::before {
  right: 100%;
}
.selectdivar select {
  padding-left: 16% !important;
  width: 118% !important;
  max-width: 118% !important;
  float: unset !important;
}
.drop {
  padding-left: 85px !important;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  display: block;
  width: 125%;
  max-width: 125%;
  height: 50px;
  float: left;
  margin: 5px 0px;
  padding: 0px 22% 0 10px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #ffffff;
  -ms-word-break: normal;
  word-break: normal;
}
.selectdiv select:focus {
  border: 1px solid #b4c9c6 !important;
  outline: none;
}

.eFeatured {
  width: 260px;
}

.featuredTxt {
  margin-left: 140px;
}

.arrow {
  position: absolute;
  height: 10px;
  right: 5%;
  top: 20px;
  border: 1px solid #4d4f5c;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
}
.arrows {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC");
  background-position: calc(100% - 0.5rem), 100% 0;
  background-position-x: 100%;
  background-position-y: 50%;
  background-size: 1.5em 1.5em !important;
  background-repeat: no-repeat !important;
  cursor: pointer !important;
}

.arrows:focus {
  border-color: #00c2ab !;
  outline: 0;
}

.arrows.dist {
  padding-right: 11.5rem !important;
}

.arrows.dis {
  padding-right: 3.5rem !important;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.points {
  font-size: 0.75em;
}

.availablePoints {
  font-weight: bold;
  color: #104961;
}

.img-overlay {
  position: relative;
  width: 100%;
  padding: 0px;
}

.card-1 {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
    rgba(241, 200, 200, 0.24) 0px 1px 2px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
}

.card-radius {
  border-radius: 13px;
}

.form-group {
  margin-bottom: 15px;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.btn-price {
  color: rgb(100, 100, 100);
  float: right;
  z-index: 1;
  cursor: pointer;
  position: relative;
  min-width: 70px;
  border-width: 1px;
  border-style: solid;
  border-color: #00c2ab !important;
  border-image: initial;
  border-radius: 5px;
  border-bottom: 1px solid #00c2ab;
}

/* .loginModal{
    text-decoration: underline;
    color: #00c2ab !important;
} */
.loginModal:hover{
  color: #333 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  height: 80px;
  opacity: 0;
}
::-webkit-scrollbar {
  width: 10px;
  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom-scrollbar::-webkit-scrollbar {
  display: block !important;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px !important;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #03a8b6 !important;
  border-radius: 20px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.caret-down,
.arrow-down,
.arrow-down-arabic {
  position: relative;
  display: block;
}
.caret-down:after {
  content: " ";
  clip-path: polygon(50% 57%, 0 0, 100% 0);
  background-color: gray;
  width: 13px;
  height: 13px;
  position: absolute;
  right: 0.75rem;
  top: calc(50% - 2px);
}
.arrow-down::after {
  content: "\2039" !important;
  transform: rotate(-90deg);
  position: absolute;
  font-size: 2.5rem;
  right: 1.5rem;
  top: 25%;
  line-height: 1.5rem;
  display: block;
}

.arrow-down-arabic::after {
  content: "\2039" !important;
  transform: rotate(90deg);
  position: absolute;
  font-size: 2.5rem;
  right: 1.5rem;
  top: 25%;
  line-height: 1.5rem;
  display: block;
}
.experiences-img {
  width: 100%;
  height: 45vw;
  object-fit: cover;
  object-position: center;
}

.btnDropDown {
  color: #000;
  background-color: #fff;
  border-color: #000;
}
.sign-out-clr{
  color: #FFEF60;
}
.lang-opacity{
  opacity: 0.5;
}
.lang-transition{
  transition: .3s ease-in-out;
}
.opacity-7{
  opacity: 0.7;
  color: white !important;
}
.opacity-1{
  color: white !important;
  opacity: 1;
  font-weight: bold !important;
}
.header-link a:focus, .header-link a:hover{
  color: white !important;
}
.switch {
  position: relative;
  display: inline-block;
  min-width: 50px;
  height: 25px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked[type="checkbox"]::after{
  content: ""; 
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #005185;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #005185;
}

input:focus + .slider {
  box-shadow: 0 0 1px #005185;
}

input:checked + .slider:before {
  content: '';
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
input[type="checkbox"] {
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  position: relative;
  top: 0.1rem;
}
input:checked[type="checkbox"]::after {
  color: white;
  content: "✓";
  background-color: var(--primaryColorTheme,#000000) !important ;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  text-align: center;
  padding-bottom: 0.2rem;
  font-size: 1rem;
  font-weight: 800;
  position: relative;
  top: 0.1rem;
  margin-top: -0.2rem;
  margin-left: -0.1rem;
}

.giftLabel {
  margin-top: -0.1rem;
}

.featureCard {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 767px) {
  .bg-md-color-light {
    background-color: #fff;
  }
  .experiences-img {
    width: 100%;
    height: 18vw;
  }
}

.terms li::marker {
  font-size: 18px;
}
/* T&C Underline start */

.underline {
  font-family: sans-serif;
  text-align: center;
  max-width: 600px;
  position: relative;
}
.underline:before {
  content: "";
  display: block;
  width: 250px;
  height: 1px;
  background: rgb(207, 204, 204);
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -2;
}
.underline:after {
  content: "";
  display: block;
  width: 250px;
  height: 1px;
  background: rgb(207, 204, 204);
  position: absolute;
  right: 0;
  top: 50%;
  z-index: -2;
}

@media (max-width: 767px) {
  .underline {
    font-family: sans-serif;
    text-align: center;
    max-width: 600px;
    position: relative;
  }
  .underline:before {
    content: "";
    display: block;
    width: 150px;
    height: 1px;
    background: rgb(207, 204, 204);
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -2;
  }
  .underline:after {
    content: "";
    display: block;
    width: 150px;
    height: 1px;
    background: rgb(207, 204, 204);
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -2;
  }
}
@media (max-width: 360px) {
  .underline {
    font-family: sans-serif;
    text-align: center;
    position: relative;
  }
  .underline:before {
    content: "";
    display: block;
    width: 120px;
    height: 1px;
    background: rgb(207, 204, 204);
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -2;
  }
  .underline:after {
    content: "";
    display: block;
    width: 120px;
    height: 1px;
    background: rgb(207, 204, 204);
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -2;
  }
}
/* End T&C  */

/* drop down arrow */
.select-arrow::after {
  content: "\02C7";
  color: rgb(180, 175, 175);
  font-size: 40px;
  right: 11px;
  top: -2px;
  height: 34px;
  padding: 15px 0px 0px 8px;
  position: absolute;
  /* pointer-events: none; */
}
.select-arrow {
  position: relative;
  /*Don't really need this just for demo styling*/
}
/* no outline */
.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
.fs-extra-large {
  font-size: 1.4rem !important;
}
/* font size 20px */
.fs-large {
  font-size: 20px !important;
}
/* font size 17px */
.fs-regular {
  font-size: 14px !important;
}
.fs-medium {
  font-size: 17.5px !important;
}
.spinner {
  margin: 70px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.content-box {
  padding-bottom: 50px !important;
}

/* view button in wallet */
.view-button {
  border-radius: 10% !important;
  color: #005E9A;
  min-height: 40px !important;
}
.view-button > p {
  color: var(--productTitleColor) !important;
}
.tambola {
  position: relative;
  /* color: white; */
}
.tambola-text {
  top: 70%;
  right: 6%;
  transform: translate(-50%, -50%);
  color: #ffff;
  font-size: 17px !important;
  margin-bottom: 0rem !important;
  font-weight: normal !important;
  color: gold !important;
}
.tambola-text-success {
  top: 60%;
  right: 6%;
  /* transform: translate(-50%, -50%); */
  color: #ffff;
  font-size: 14px !important;
  margin-bottom: 0rem !important;
  font-weight: normal !important;
  color: gold !important;
}
.tambola-number {
  top: 9% !important;
  right: 15%;
  font-weight: bold;
  /* transform: translate(-50%, -50%); */
  color: #ffff;
  color: gold !important;
  font-size: 1.4rem !important;
}
.tambola-cong {
  top: 35%;
  right: 8%;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: #ffff;
  color: gold !important;
  font-size: 1.4rem !important;
}
.tambola-text-mobile {
  top: 50%;
  right: 4%;
  transform: translate(-50%, -50%);
  color: #ffff;
  font-size: 0.5rem !important;
  color: gold !important;
}
@media (min-width: 360px) {
  .tambola-text-mobile {
    top: 50%;
    right: 3% !important  ;
    /* transform: translate(-50%, -50%); */
    color: #ffff;
    font-size: 0.5rem !important;
    color: gold !important;
  }
  .terms-mobile {
    position: absolute;
    top: 1px;
    right: 10px;
    color: #fff;
    font-size: 5px !important;
    opacity: 0.7;
  }
  .terms-mobile-arabic {
    position: absolute;
    top: 1px;
    right: 10px;
    color: #fff;
    font-size: 5px !important;
    opacity: 0.7;
  }
}
/* .tambola-text-mobile-success{
  top: 55%;
  right: 4%;
  transform: translate(-50%, -50%);
  color: #ffff;
  font-size: .7rem !important;
  color: gold !important;
} */
/* .tambola-id-mobile{
  top: 82%;
  right: 2%;
  transform: translate(-50%, -50%);
  color: #ffff;
  font-size: .5rem !important;
  color: gold !important;
} */
@media (max-width: 320px) {
  .tambola-text-mobile {
    top: 50%;
    right: 3% !important;
    /* transform: translate(-50%, -50%); */
    color: #ffff;
    font-size: 0.5rem !important;
    color: gold !important;
  }
  .tambola-text-mobile-success {
    top: 35%;
    right: 6%;
    /* transform: translate(-50%, -50%); */
    color: #ffff;
    font-size: 0.4rem !important;
    color: gold !important;
  }
  .tambola-cong-mobile {
    top: 13% !important;
    right: 12%;
    transform: translate(-50%, -50%);
    color: #ffff;
    color: gold !important;
    font-size: 0.7rem !important;
  }
  .tambola-id-mobile {
    top: 80%;
    right: 1%;
    transform: translate(-50%, -50%);
    color: #ffff;
    font-size: 0.4rem !important;
    color: gold !important;
  }
  .terms-mobile {
    position: absolute;
    top: 1px;
    right: 10px;
    color: #fff;
    font-size: 5px !important;
    opacity: 0.7;
  }
  .terms-mobile-arabic {
    position: absolute;
    top: 1px;
    right: 10px;
    color: #fff;
    font-size: 5px !important;
    opacity: 0.7;
  }
  .tambola-number-mobile {
    top: 14% !important;
    right: 7%;
    transform: translate(-50%, -50%);
    color: #ffff;
    color: gold !important;
    font-size: 0.5rem !important;
  }
}
@media (min-width: 360px) {
  .tambola-cong-mobile {
    top: 13% !important;
    left: 21% !important;
    transform: translate(-50%, -50%);
    color: #ffff;
    color: gold !important;
    font-size: 0.5rem !important;
  }
  .tambola-text-mobile-success {
    top: 55%;
    right: 1%;
    transform: translate(-50%, -50%);
    color: #ffff;
    font-size: 0.4rem !important;
    color: gold !important;
  }
  .tambola-id-mobile {
    top: 82%;
    right: 1%;
    transform: translate(-50%, -50%);
    color: #ffff;
    font-size: 0.5rem !important;
    color: gold !important;
  }
  .tambola-number-mobile {
    top: 14% !important;
    right: 7%;
    transform: translate(-50%, -50%);
    color: #ffff;
    color: gold !important;
    font-size: 0.7rem !important;
  }
}

.tambola-number-mobile2 {
  top: 3% !important;
  right: 16%;
  /* transform: translate(0, -50%); */
  color: #ffff;
  color: gold !important;
  font-size: 0.7rem !important;
}

.tambola-success-mobile {
  top: 13% !important;
  left: 20%;
  transform: translate(-50%, -50%);
  color: #ffff;
  color: gold !important;
  font-size: 0.7rem !important;
}
.terms {
  position: absolute;
  bottom: 0.2rem;
  right: 4px;
  color: #fff;
  opacity: 0.7;
  font-size: 0.6rem;
}
.terms-arabic {
  position: absolute;
  bottom: 10px;
  right: 16px;
  color: #fff;
  opacity: 0.7;
}
/* .terms-mobile{
  position: absolute;
  bottom: 1px;
  right: 10px;
  color: #fff;
  font-size: 9px !important;
  opacity: 0.7;
} */

@media (max-width: 767px) {
  .credit-Card {
    padding-left: 0 !important;
  }
  .terms-contact {
    justify-content: center;
    text-align: center !important;
  }
  .main-div{
    height: 100% !important;
  }
  body > div:nth-child(2){
    left: 0 !important;
  }
  ::-webkit-scrollbar{
    display: none;
  }
}

.btn-outline-dark:hover{
  color: #fff !important;
}

.viewDetails {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
@media (max-width: 767px) {
  .mobile-margin-top {
    margin-top: 4rem !important;
  }
}
.terms-link {
  text-decoration: underline !important;
  color: #fff !important;
}
.modal-header {
  border-bottom: none !important;
}
.promotion-win {
  position: absolute;
  bottom: 7px;
  right: 3.7rem;
  /* background-color: black; */
  color: gold;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}
.promotion {
  position: absolute;
  bottom: 7px;
  right: 6rem;
  /* background-color: black; */
  color: gold;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

@media (min-width: 321px) {
  .promotion-mobile {
    position: absolute;
    top: 3px;
    bottom: 7px;
    right: 1rem;
    /* background-color: black; */
    color: gold;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  .promotion-win-mobile {
    position: absolute;
    bottom: 1.5px;
    right: 5px;
    /* background-color: black; */
    color: gold;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    line-height: 0.5rem;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .promotion-win-mobile-arabic {
    position: absolute;
    bottom: 1.5px;
    right: 0.9rem !important;
    /* background-color: black; */
    color: gold;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    line-height: 0.5rem;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .cong-i-4 {
    font-size: 0.5rem !important;
  }
  .id-eng {
    font-size: 0.4rem !important;
  }
  .id-arabic {
    font-size: 0.4rem !important;
    margin-top: 1px;
  }
  .win-ar {
    font-size: 0.5rem;
    margin-top: 1px !important;
  }
  .win-en {
    font-size: 0.4rem !important;
  }
}
@media (max-width: 320px) {
  .promotion-mobile {
    position: absolute;
    top: 3px;
    bottom: 7px;
    right: 0.6rem;
    /* background-color: black; */
    color: gold;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  .promotion-win-mobile {
    position: absolute;
    bottom: 1px;
    right: 7px !important;
    /* background-color: black; */
    color: gold;
    padding-left: 20px;
    padding-right: 10px;
    text-align: center;
    line-height: 55%;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .cong-i-4 {
    font-size: 0.5rem !important;
  }
  .id-eng {
    font-size: 0.3rem !important;
  }
  .id-arabic {
    font-size: 0.4rem !important;
    margin-top: 1px;
  }
  .promotion-win-mobile-arabic {
    position: absolute;
    bottom: 1.5px;
    right: 0.7rem !important;
    /* background-color: black; */
    color: gold;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    line-height: 0.5rem;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .win-ar {
    font-size: 0.4rem;
    margin-top: 1px !important;
  }
  .win-en {
    font-size: 0.4rem !important;
  }
  .id-eng {
    font-size: 0.4rem !important;
  }
  .id-arabic {
    font-size: 0.4rem !important;
    margin-top: 1px;
  }
}
.terms-link:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.text-blackColor {
  color: var(--primaryTextTheme,#000000 ) !important;
}
/* Home Page Login Button */
.Forget-Password:hover {
  color: #136982;
  text-decoration: underline;
}

element.style {
  z-index: 1400;
}
.position-fixed {
  position: fixed !important;
}
.overflow-auto {
  overflow: auto !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
/* .d-flex {
  display: flex !important;
} */

.my-acc p{
  padding: 0.25rem 0.5rem !important;
}

.my-acc a{
  color: #000 !important;
}

.forget-password-modal .LoginModel {
  -webkit-box-shadow: 10px 10px 20px 8px rgba(224, 224, 224, 1);
  -moz-box-shadow: 10px 10px 20px 8px rgba(224, 224, 224, 1);
  box-shadow: 10px 10px 20px 8px rgba(224, 224, 224, 1);
}
/* my profile */
.blue-color{
  color: #4681C2;
}
.blu-btn{
  background-color: #4681C2 !important;
}

/* sign up */

.start-text{
  text-align: start !important;
}

.lname-pad{
  padding-left: 0.5rem !important;
}
.lname-pad2{
  padding-right: 0.5rem !important;
}

.login-color{
  color: #5381F8 !important;
}

.reward-no{
  border: 1px solid #4681C2;

}

.reward-img{
  padding: 1rem;
}

.reward-card{
  height: 1.5rem;
  background-color: #4681C2;
  color: #fff;
}

.Forget-Password {
  width: fit-content !important;
}

/* .LoginBtn1 {
  margin-left: 5.8rem;
  text-align: start;
}

.LoginBtn2 {
  margin-right: 5.8rem;
  text-align: start;
} */

.LoginBtn1 button,
.LoginBtn2 button {
  padding: 12px 30px 12px 30px;
}

.LoginBtn3 {
  margin-left: 1rem;
  text-align: start;
}

.LoginBtn4 {
  margin-right: 1rem;
  text-align: start;
}

.LoginBtn3 button,
.LoginBtn4 button {
  /* padding: 12px 30px 12px 30px; */
}

/* Login Model Pop */

.LoginModel {
  width: 25rem;
  padding: 0 60px 20px 60px;
  border-radius: 17px;
}
.LoginModel .cross1 {
  top: auto;
  left: 110.5%;
}
.LoginModel .cross1-reset {
  top: auto;
  left: 65.5%;
}
.LoginModel .cross3 {
  top: auto;
  left: 21%;
}

.LoginModel .cross2 {
  top: auto;
  left: 113.5%;
}
.LoginModel .cross4 {
  top: auto;
  left: 25%;
}

.LoginModel .cancel {
  cursor: pointer;
}

.LoginModel .cursor-pointer {
  width: 20px;
}

.LoginIcon img {
  top: 10px;
  margin-left: 10px;
}

.LoginIcon .img2 {
  top: 10px;
  margin-right: 10px !important;
}

.LoginIcon input {
  padding: 0.5rem 0.5rem 0.5rem 2.1rem;
}

.LoginIcon .input2 {
  padding: 0.5rem 2.1rem 0.5rem 0.5rem;
}

/* Login Input Placeholder */
.login-input::placeholder {
  opacity: 0.5;
}

/* acc confirm */
.acc-confirm{
  width: 30%;
}
.acc-confirm-img svg{
  width: 27%;
}
.reset-password svg{
  width: 26%;
}

.wallet-search:focus{
  outline: none;
}

.width-35{
  width: 35%;
}

.width-12{
  width: 12%;
}

.width-15{
  width: 15%;
}
.width-18{
  width: 18%;
}

.w-0{
  width: 0;
}

.redempton-pop svg{
  width: 25%;
}

.check-mail svg{
  width: 30%;
}

@media (max-width : 767px) {
  .acc-confirm{
    width: 90%;
  }
  .width-35{
    width: 95%;
  }
  .position-fixed-mobile{
    position: fixed;
  }
}

@media (min-width: 767px) and (max-width: 790px) {
  .tabletFontSize span {
    font-size: small !important;
  }
}

@media (min-width: 768px) and (max-width: 1359px) {
  .custinput {
    width: 35% !important;
  }
  .cdiv {
    flex-wrap: wrap;
  }
  .cusbtn {
    width: 100%;
  }
}
.customDropdown .dropdown-menu {
  height: 210px !important;
  overflow-y: scroll !important;
}
.customDropdown .dropdown-menu::-webkit-scrollbar {
  display: block !important;
}
.customDropdown .dropdown-menu::-webkit-scrollbar {
  width: 5px !important;
}
.customDropdown .dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #6fac45 !important;
  border-radius: 20px !important;
}

/* top header css */
.afterdivider::after {
  content: "";
  position: absolute;
  border-width: 1px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  /* width: 1rem; */
  margin-left: 10px;
  height: 2rem;
}

.afterdivider2::after {
  content: "";
  position: absolute;
  border-width: 1px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  /* width: 1rem; */
  margin-right: 10px;
  height: 2rem;
}

/* // featured categories css */
.customcarousel .carousel-control-next,
.carousel-control-prev {
  justify-content: start !important;
  width: 3% !important;
}
.customcarousel .carousel-control-next,
.carousel-control-next {
  justify-content: end !important;
  width: 3% !important;
}
.customcarousel .carousel-item img {
  width: 25% !important;
}
.pointstable tr td{
  min-width: 137px;
}
#status::first-letter{
  text-transform: capitalize;
}