.sideNav_container {
    background-color: #F4F7FA;
    width: 300px;
    height: 100vh;
    position: relative;
    z-index: 3000 !important;
    overflow-y: auto !important;    
    animation: slideMe 0.8s ease-in;
  }
  .sideNav_container .language-toggler{
    position: absolute;
    top: 80%;
    width: 100%;
    padding: 1rem;
}
  @keyframes slideMe {
    0% {
      transform: translateX(-800px);
    }
  
    80% {
      transform: translateX(0px);
    }

  }
  
  .sideNav_signin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 50px;
    line-height: 40px;
    margin-left: 20px;
    margin-bottom: 4rem;
  }

  .sideNav-Welcome-text{
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #272629;
    margin-top: 20px;
  }
  .sideNav-Logout-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FF6060;
  }
  .sideNav-link-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #272629;
  }
  .sideNav_signin_logo_container {
    /* margin-left: 10px;
    margin-right: 10px; */
    margin-top: 50px;
  }
  
  .sideNav_signin_logo {
    height: 60px;
  }
  
  .sideNav_signin_heading h5 {
    margin-bottom: 0 !important;
  }
  
  .sideNav_main_container {
    background-color: #F4F7FA;
    /* margin-top: 50px; */
  }
  
  .sideNav_main_trending_container {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px lightgrey;
    border-top: solid 1px lightgrey;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  
  .sideNav_main_heading {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 36px;
    color: #000000;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
  
  /* .sideNav_main_link {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 36px;
    text-decoration: none;
    color: black;
    font-size: 13px;
  } */
  
  .link_container{
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      /* padding-top: 10px;
      padding-bottom: 10px; */
  }
  
  .sideNav_main_link{
      text-decoration: none;
      color: black;
      font-size: 13px;
  }
  
  /* .link_container:hover {
    color: black;
    background-color: #eaeded;
  } */
  /* Rotate icon 180 degree */
.rotate-180 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
#sidenavCountry
   {
    border: none !important;
    background-color:#FAFAFF !important;
  }
