.overlayContainer{
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.50);
    cursor: pointer;
    z-index: 1600 !important;
    animation: slideMe 0.8s ease-in;
}
.close_sidenav{
    position: relative;
    top: 10px;
    left: 5px;
    color: #ffffff;
    width: 30px;
    height: 30px;
}
@keyframes fadeIn {
    0%{ opacity: 0; }
    100% { opacity: 1; }
} 
